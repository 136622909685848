@import "styles";

.situations_block {
  flex-shrink: 1;
  margin-right: 4rem;
  &:last-child {
    flex-grow: 1;
    margin-right: 0;
  }
}
