@import "styles";

.clearable_button {
  display: inline-flex;
  border: 1px solid;
  border-color: $button-default;
  border-radius: 0.25rem;
  background-color: transparent;
  color: $button-default;
  &-CLEARABLE {
    composes: clearable_button;
    background-color: $button-default;
    color: white;
  }
  &:hover,
  &:focus {
    background-color: $button-default;
    color: white;
  }
}

.clear_button {
  display: flex;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  align-items: center;
  &:hover,
  &:focus {
    background-color: $button-hover;
    color: white;
  }
  & > svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}