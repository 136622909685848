@import "styles";
@import "./top-bar";

$horizontal_gutter: 2rem;

.top_bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $top_bar_layer;
  display: grid;
  height: $top_bar_height;
  background-color: $bg-topnav;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
}

.top_bar_button {
  composes: menu_button from global;
  margin-right: 1rem;
}

.toolbar {
  padding-left: 4rem;
}

.system_toolbar {
  display: flex;
  justify-content: flex-end;
}

.client_selector {
  transition: transform 0.3s $bounce_slow_start_end;
  transform: translate(0) scale(1);
  transform-origin: top left;
}

.client_selector-NO_CLIENT {
  composes: client_selector;
  transform: translate(5rem, 4rem) scale(2);
  & > ul {
    transform: scale(0.5);
    transform-origin: top left;
  }
}

.grow {
  flex-grow: 1;
}
