@import "styles";

.show_more {
  display: inline-flex;
    margin-right: 0.375rem;
    width: 1rem;
    height: 1rem;
    border: 1px solid $button-default;
    border-radius: 0.125rem;
    color: $button-default;
    font-size: 1rem;
    line-height: 1;
    align-items: center;
    justify-content: center;
}