@import "styles";

.search_wrapper {
  display: flex;
}

.search_text_wrapper {
  flex-grow: 1;
}

.search_text {
  display: inline-block;
  margin-right: 2.5rem;
  margin-bottom: 1rem;
  max-width: 10rem;
  &-LARGE {
    composes: search_text;
    max-width: 15rem;
  }
}

.button_verify_wrapper {
  flex-shrink: 0;
  align-items: flex-start;
  display: flex;
}

.button_verify {
  composes: button_main from global;
  margin-top: 1rem;
  & > svg {
    margin-right: 0.5rem;
  }
}

.checkbox {
  margin-bottom: 0.5rem;
}

.errors_wrapper {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: $danger-background;
  color: $danger-regular;
  & > p {
    margin-bottom:0;
  }
}

.no_selected_services {
  font-style: italic;
}

.selected_services_list {
  color: $text-fainter;
  font-style: italic;
  font-size: 0.875rem;
  & > span {
    display: inline-block;
    margin-right: 0.5rem;
    &::before {
      display: inline-block;
      margin-right: 0.5rem;
      color: $text-faintest;
      content: "\2022";
    } 
    &:first-child::before {
      display: none;
      content: "";
    }
  }
}