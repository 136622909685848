@import "../app/side-bar/side-bar";
@import "../app/top-bar/top-bar";

/* 
* Main tag's className in features is defined by the className props - commom 
* to all features.
*/

/* -----------------------------------------------------------------
*                                                            gutters
*/
$feature_top_gutter: 4rem;
$feature_right_gutter: 8rem;
$feature_bottom_gutter: 10rem;
$feature_left_gutter: 4rem;

/* -----------------------------------------------------------------
*                                                                box
*/
$feature_min_padding_top: $feature_top_gutter + $top_bar_height;
$feature_min_padding_right: $feature_right_gutter;
$feature_min_padding_bottom: $feature_bottom_gutter;
$feature_min_padding_left: $side_bar_hover_width + $feature_left_gutter;
