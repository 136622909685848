@import "styles";

.wrapped_text {
  position: relative;
  & > input,
  & > textarea {
    width: 100%;
  }
}

.wrapper {
  position: relative;
  & > input,
  & > textarea {
    width: 100%;
  }
  &-WITH_ICON {
    composes: wrapper;
    & > input,
    & > textarea {
      padding-right: 2em;
    }
  }
}

.icon {
  position: absolute;
  right: 0.5em;
  font-size: 1.25em;
  &-SEARCH {
    composes: icon;
    transform: rotate(45deg);
  }
  &-ARROW_DOWN {
    composes: icon;
  }
}

.label {
  margin-bottom: 0.125rem;
  color: $text-faint;
  font-size: 0.875em;
}

.input {
  border: none;
  background-color: inherit;
  color: inherit;
  font-size: inherit;
  &::placeholder {
    font-size: 0.875em;
  }
}

.input-BORDERED {
  composes: input;
  padding: 0.25em 0.5em;
  border: 1px solid $line-component;
  border-radius: 0.25em;
  background-color: white;
  color: $text-regular;
  &::placeholder {
    color: $text-regular;
  }
}

.input-TRANSPARENT {
  composes: input;
  background-color: inherit;
  &::placeholder {
    color: $text-regular;
  }
  & ~ .validity_message {
    bottom: -1rem;
  }
}

.input-BORDERED_ON_HOVER {
  composes: input-BORDERED;
  border-color: transparent;
  background-color: inherit;
  &:hover, &:focus {
    border-color: $line-component;
  }
}

/** Color scheme for inputs in navigation pane. */
.input-NAV {
  composes: input;
  color: $text-menu-regular;
  &::placeholder {
    color: $text-menu-faint;
  }
}

.invalid {
  color: $danger-regular;
}

.validity_message {
  position: absolute;
  bottom: -1.25rem;
  overflow: hidden;
  margin: 0;
  font-size: 0.75em;
  & > span {
    display: inline-block;
    transition: transform 0.2s 0.1s $ease_slow_end;
    transform: translateY(-100%);
  }
}

.validity_message-INVALID {
  composes: validity_message;
  color: $danger-regular;
  & > span {
    transform: translateY(0);
  }
}

.validity_message-VALID {
  composes: validity_message;
  color: $success-regular;
  & > span {
    transform: translateY(0);
  }
}
