.svg-small {
  width: 1rem;
  height: 1rem;
}

.svg-regular {
  width: 1.5rem;
  height: 1.5rem;
}

.svg-large {
  width: 2rem;
  height: 2rem;
}
