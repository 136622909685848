@import "styles";
@import "../top-bar/top-bar";

/* -----------------------------------------------------------------
*                                                             colors
*/
/* Color of the top part. */
$side_bar_section_bg: $bg-main;
/* Color of the bottom part. */
$side_bar_subsection_bg: $bg-menu;

/* -----------------------------------------------------------------
*                                                                box
*/
/* Full width of the sidebar. */
$side_bar_width: 3.5rem;
/* Width of the hover area that is always visible. */
$side_bar_hover_width: 0.875rem;

$side_bar_top: $top_bar_height;
