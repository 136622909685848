@import "styles";

.toggle_list {
  color: $text-regular;
}

.toggle_list-STRONG {
  color: $text-strong;
}

.list_item {
  display: inline;
  cursor: pointer;
  margin-right: 1.25rem;
  &:hover,
  &:focus {
    color: $text-selected;
  }
}

.list_item-DISABLED {
  composes: list_item;
  opacity: 0.15;
  cursor: default;
}

.list_item-SELECTED {
  composes: list_item;
  color: $text-selected;
}
