@import "styles";

.help {
  composes: menu from "../popup-menu.module.scss";
  padding: 1rem 1.5rem;
  max-width: 50vw;
  max-height: 60vh;
  & mark {
    margin-right: 0.5rem;
    padding: 0 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
    background-color: rgba(0, 0, 0, 0.2);
    color: inherit;
    font-style: italic;
  }
  & em {
    color: $attention-regular;
    font-style: normal;
  }
  & > dt {
    margin-bottom: 0.5rem;
  }
  & > dd {
    margin-bottom: 1.5rem;
    margin-left: 2.5rem;
    & > p {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.help-COLLAPSED {
  composes: menu from "../popup-menu.module.scss";
  padding: 0 0.375rem;
  border-radius: 50%;
}

.header {
  position: relative;
  margin-bottom: 1rem;
  & > button {
    position: absolute;
    top: -0.25rem;
    right: -0.5rem;
    color: $danger-regular;
  }
  & > i {
    display: block;
    color: $text-menu-faint;
  }
}

.toggle_collapse_button {
  margin-right: 0.5rem;
  width: 1rem;
  color: $text-menu-fainter;
}
