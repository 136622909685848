@import "styles";

.datepicker_menu {
  width: 100%;
  cursor: pointer;
  &:hover > p > .down_icon,
  &:focus-within > p > .down_icon {
    visibility: visible;
  }
  & > p {
    display: flex;
    margin: 0;
    width: 100%;
    color: $icon-selected;
    text-align: left;
    & > span:first-child {
      overflow: hidden;
      padding-right: 0.25rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
    }
  }
}

.datepicker_menu-POPUP_ACTIVE {
  composes: datepicker_menu;
  color: $icon-selected;
}

.down_icon {
  visibility: hidden;
}

.menu {
  composes: menu from "../popup-menu.module.scss";
  left: 0;
  display: flex;
  flex-direction: column;
  margin-top: 0.375rem;
  min-width: 8rem;
  max-height: none;
  border-top-left-radius: 0;
}

.tree {
  overflow-y: auto;
  max-height: calc(max(16rem, 100vh - 21rem));
}

.ok_button {
  margin: 0.5rem 0.75rem;
  padding: 0.25rem 1rem;
  border: 1px solid #3a4353;
  border-radius: 0.25rem;
  align-self: flex-end;
}
