@import "styles";

.day_picker > :global(.DayPickerInput) > input {
  border: none;
  background-color: $bg-main;
}

.filter_button {
  margin-right: 0.75rem;
}

.popup {
  position: relative;
  z-index: 1000;
  display: inline-flex;
  padding: 0.125rem 0.75rem;
  border-radius: 0.25rem;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  & > svg {
    margin-right: 0.375rem;
  }
}

.popup_menu_added_styles {
  top: 1.75rem;
  left: 0;
  overflow: visible;
}

.popup_menu_height_limit {
  overflow: auto;
  max-height: 16rem;
}
