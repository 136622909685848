@import "styles";

.label {
  margin-bottom: 0;
  font-size: 0.75rem;
  color: $text-fainter;
}

.danger {
  color: $danger-regular;
}

.success {
  color: $success-regular;
}