@import "styles";

.text_input {
  width: 30rem;
  & > p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

.text {
  margin-bottom: 1.5rem;
  width: 100%;
}
