@import "styles";

.card {
  padding: 0;
}

.text_input {
  margin: 1rem;
}

.item {
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
  cursor: pointer;
  &-SELECTED {
    composes: item;
    border-left: 2px solid $text-selected;
    color: $text-selected;
    & > .label {
      color: $text-selected;
    }
  }
  &:hover {
    color: $text-selected;
    & > .label {
      color: $text-selected;
    }
  }
}

.label {
  margin-bottom: 0;
  color: $text-regular;
  line-height: 1.1;
}

.secondary_label {
  margin-bottom: 0;
  font-style: italic;
  font-size: 0.875em;
}