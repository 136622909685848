@import "styles";
@import "../../app/top-bar/top-bar";
@import "../../app/side-bar/side-bar";

/* -----------------------------------------------------------------
*                                                             colors
*/
$table_bg: $bg-main;
$table_text_color: $text_regular;
$table_line_color: $line-main;
$table_header_color: $bg-card;

/* -----------------------------------------------------------------
*                                                                box
*/
$table_sticky_top: $top_bar_height;
$table_sticky_left: $side_bar_hover_width;
$table_row_height: 1.5rem;
$table_cell_vertical_padding: 0.375rem;
$table_cell_horizontal_padding: 0.75rem;
$header_cell_padding: 0.625rem $table_cell_horizontal_padding;

/* -----------------------------------------------------------------
*                                                              fonts
*/
$table_font_size: 0.875rem;
