@import "styles";

.donut {
  transform: rotate(-90deg) translateZ(0);
}

.donut_size {
  width: 1.25rem;
  height: 1.25rem;
}

.pie {
  fill: $icon-faint;
  stroke: $icon-strong;
  stroke-width: 20;
}

.donut {
  fill: $icon-faint;
}

.spin {
  transform: rotate(-135deg) translateZ(0);
  /* fast-out, slow-in */
  animation: spin 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes spin {
  0% {
    transform: rotate(-135deg) translateZ(0);
  }
  100% {
    transform: rotate(225deg) translateZ(0);
  }
}
