@import "styles";

$card-border-radius:  0.125rem;
$card-small-padding: 1rem 1.5rem;
$card-large-padding: 1.5rem 2rem;

.card {
  border: 1px solid $line-main;
  border-radius: $card-border-radius;
  background-color: $bg-card;
  &-PADDED{
    composes: card;
    padding: $card-small-padding;
  }
}

.content_above_footer {
  padding: $card-large-padding;
}