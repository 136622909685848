@import "styles";

.lil_donut {
  position: absolute;
  width: 0.75rem;
}

.link {
  composes: tooltip_parent from global;
  position: relative;
  display: inline-block;
}

.inactive_link {
  composes: link;
  color: $icon-regular;
  &:hover {
    color: $icon-regular;
  }
}

.tooltip {
  composes: tooltip from global;
  right: 0;
  bottom: -0.25rem;
  transform: translateY(100%);
}
