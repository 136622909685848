@import "styles";

.parents_list {
  margin-left: 1rem;
}

.leaves_list {
  margin-left: 2.5rem;
}

.item {
  margin-right: 1rem;
  padding: 0.125rem 0;
}

.toggle_collapse_button {
  padding: 0 0.5rem;
  width: 1.75rem;
  color: $text-menu-fainter;
}

.selection_button:focus {
  text-decoration: underline;
}

.selection_button:hover {
  color: $text-selected;
  text-decoration: underline;
}

.selection_button-SELECTED {
  composes: selection_button;
  color: $text-selected;
  &:hover {
    color: $danger-regular;
  }
}
.selection_button-PARTIALLY_SELECTED {
  color: $text-partially-selected;
  &:hover {
    color: $text-selected;
  }
}
