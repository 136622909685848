@import "../feature-groups/features";
@import "./top-bar/top-bar";

/*
* ALL features `main` tags must have this class.
*/
.feature {
  padding-top: $top_bar_height;
  padding-bottom: $feature_min_padding_bottom;
  /* Needed for header sticky left position to work. */
  width: max-content;
  max-width: 100%;
  color: $text-faint;
  & > section {
    padding: 2rem 4rem 4rem;
  }
}

.maintenance_card {
  text-align: center;
  font-size: 1.25rem;
}
