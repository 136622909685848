@import "styles";

.top_bar_wrapper {
  position: relative;
  height: 3rem;
  & > .logo_svg {
    background-color: $bg-topnav;
    padding: 0 1rem;
    border-radius: 2rem;
  }
}
