@import "styles";
@import "../../app/side-bar/side-bar";

.header {
  position: sticky;
  left: 0;
  z-index: $feature_header_layer;
  padding-top: 4rem;
  padding-bottom: 0;
  padding-left: 2rem;
  width: 100vw;
  background-color: $bg-nav;
  border-bottom: 1px $line-main solid;
  letter-spacing: 1px;
  font-size: 0.75rem;
}

.title {
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  &-CLOSE {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.navigation {
  margin-right: 1rem;
  padding: 0.5rem 0.75rem;
  color: $text-regular;
  font-size: 0.875rem;
  & > sup {
    font-size: 0.625rem;
    opacity: 0.7;
  }
  &:hover,
  &:focus {
    color: $text-selected;
    text-decoration: none;
  }
}

.navigation-ACTIVE {
  composes: navigation;
  color: $text-selected;
  border-bottom: 2px solid $text-selected;
}
