@import "../table";
@import "styles";

.row {
  border-bottom: 1px solid $table_line_color;
}

.number_cell {
  position: sticky;
  left: $table_sticky_left;
  z-index: $table_filters_layer;
  background-color: $bg-main;
  color: $text-fainter;
  text-align: center;
  font-size: 0.75rem;
}

.cell {
  overflow: hidden;
  padding: $table_cell_vertical_padding $table_cell_horizontal_padding;
  max-width: 70vw;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: $table_font_size;
  &-left {
    composes: cell;
    text-align: left;
  }
}

.reference_row {
  border-bottom: none;
  opacity: 0;
  user-select: none;
}
