.labeled_total {
  margin: 2rem 2.75rem 1rem;
}

.table_wrapper {
  padding-bottom: 1rem;
}

.empty_table_messagebox {
  margin: 2.5rem;
}