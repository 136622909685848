@import "styles";

.search_status {
  display: flex;
  padding: 1.5rem;
}

.credential_wrapper {
  display: inline-block;
  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }
}

.credential_title {
  margin-bottom: 0;
}

.progress_bar {
  margin: 0 3rem;
  flex-grow: 1;
}

.results_toolbar {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.button_stop {
  display: flex;
  align-items: center;
  & > svg {
    display: inline-block;
    margin-right: 0.25rem;
  }
}

.files_bundle_button {
  margin-right: 0.5rem;
}
