@import "styles";

.wrapper {
  height: 0.125rem;
  border-radius: 0.0625rem;
  background: $line-main;
  width: 100%;
}

.wrapper > div {
  height: 100%;
  border-radius: 0.0625rem;
  background: $icon-faint;
}
