@import "styles";

.card_section {
  border-top: 1px solid $line-main;
  padding-bottom: 0.75rem;
  &:first-child {
    border-top: none;
  }
}

.header {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-top: 0.75rem;
}

.title {
  flex-grow: 1;
  margin: 0;
}