@import "styles";

.issue_icon {
  composes: tooltip_parent from global;
  position: relative;
  top: -1px;
  display: inline-block;
  margin-right: 0.375rem;
}

.icon {
  width: 0.75rem;
  height: 0.75rem;
  &-danger {
    composes: icon;
    color: $danger-regular;
  }
  &-attention {
    composes: icon;
    color: $attention-regular;
  }
}

.tooltip {
  composes: tooltip from global;
  bottom: -0.25rem;
  left: 0;
  margin-right: 0.5rem;
  transform: translateY(100%);
}
