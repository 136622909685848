@import "../footer-bar.module.scss";

.status_text {
  margin: 0 $footer_icons_gutter * 2;
  flex-grow: 1;
  justify-content: flex-end;
  &-REGULAR {
    composes: status_text;
    color: $text-faint;
  }
  &-ERROR {
    composes: status_text;
    color: $danger-regular;
  }
  & > button {
    margin-left: 1rem;
  }
}
