@import "styles";

.info_box {
  padding: 1rem 1.5rem;
  border: 1px solid;
  border-radius: 0.25rem;
  &-SUCCESS {
    composes: info_box;
    background-color: $success-background;
    border-color: $success-faint;
    color: $success-strong;
  }
  &-ATTENTION {
    composes: info_box;
    background-color: $attention-background;
    border-color: $attention-faint;
    color: $attention-strong;
  }
  &-DANGER {
    composes: info_box;
    background-color: $danger-background;
    border-color: $danger-faint;
    color: $danger-regular;
  }
  &-INFO {
    composes: info_box;
    background-color: $info-background;
    border-color: $info-faint;
    color: $info-strong;
  }
}