@import "../table";

/* TODO: fix filter height and derive 2.0625rem from filter height + filter.
*  cell padding top */
$filters_height: 2.0625rem;

.cell {
  position: sticky;
  top: $table_sticky_top;
  z-index: $table_filters_layer;
  height: $filters_height;
  background-color: $table_bg;
  font-size: $table_font_size;
}

.first_cell {
  composes: cell;
  left: $table_sticky_left;
  z-index: $popup_menus_layer;
  width: 2rem;
  text-align: center;
  & > button {
    position: relative;
    color: $icon-regular;
    &:hover,
    &:focus {
      color: $icon-regular;
      & > .remove_all {
        opacity: 1;
      }
    }
    & > span {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0.625rem;
    }
  }
}

.remove_all {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.75rem;
  height: 0.75rem;
  background-color: $bg-main;
  color: $danger-regular;
  opacity: 0;
}
