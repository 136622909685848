/* -----------------------------------------------------------------
*                                                 white theme colors
*/
$bg-main: #F7F7F6;
$bg-table-stripe: #F0F0EF;
$bg-card: #FFFFFF;
$bg-card_header: #FAFAFA;
$bg-card_footer: #FCFCFC;
$bg-nav: #FFFFFF;
$bg-success: #DEF5E9;
$bg-menu: #227FB9;
$bg-menu_hover: #1370aa;
$bg-topnav: #2D98DA;
$bg-disabled: #ECECEE;

$line-main: #E0E0E2;
$line-component: #cfd5de;
$line-strong: #c2c7cf;

$text-faintest: #AFB1B3;
$text-fainter: #9FA1A3;
$text-faint: #8A8D8F;
$text-regular: #75787A;
$text-strong: #676A6C;
$text-selected: #2D98DA;
$text-partially-selected: #6CB7E5;

$text-menu-fainter: #BDD9EA;
$text-menu-faint: #A7CCE3;
$text-menu-regular: #FFFFFF;
$text-menu-strong: #FFFFFF;
$text-menu-hover: #FFFFFF;

$button-default: #2D98DA;
$button-hover: #077CC5;

$icon-menu: #D5EAF8;
$icon-menu-hover: white;
$icon-fainter: #E2E3E3;
$icon-faint: #D4D4D5;
$icon-regular: #B4B4B5;
$icon-strong: #A4A4A5;
$icon-selected: #2D98DA;
$icon-hover: #2D98DA;

$info-regular: #2D98DA;
$info-strong: #077CC5;
$info-faint: #a8d5f0;
$info-background: #deeef8;

$success-regular: #20BF6B;
$success-strong: #1CA65D;
$success-faint: #a1ebc3;
$success-background: #e1ffef;

$attention-strong: #DF9E17;
$attention-regular: #FED330;
$attention-faint: #fce07d;
$attention-background:#FCF5E8;

$danger-strong: #c5494f;
$danger-regular: #fc6f76;
$danger-faint: #ffbcc0;
$danger-background: #ffecee;


/*
Font sizes: 
  base size: heading--3 (16px) 
  --heading--1: 1.5rem;
  --heading--2: 1.25rem;
  --heading--3: 1rem;
  --heading--4: 0.88rem;
  --text--regular: 0.88rem;
  --text--small: 0.75rem;
*/