@import "styles";
@import "daypicker";

.menu_button {
  color: $icon-menu;
  & > * {
    color: $icon-menu;
  }
  &:hover,
  &:focus {
    color: $icon-menu-hover;
    & > * {
      color: $icon-menu-hover;
    }
  }
}

.action {
  cursor: pointer;
  &:hover,
  &:focus {
    color: $icon-selected;
  }
}

.soft_action {
  cursor: pointer;
  &:hover,
  &:focus {
    color: $text-partially-selected;
  }
}

.link {
  color: $button-default;
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.toggle_on {
  color: $text-selected;
  &:hover,
  &:focus {
    color: $danger-regular;
  }
}

.button_base {
  padding: 0.5rem 1rem;
  border: 1px solid;
  border-radius: 0.25rem;
}

.button {
  composes: button_base;
  border-color: $line-main;
  &:hover,
  &:focus {
    border-color: $line-component;
    background: $button-hover;
  }
}

.tooltip {
  position: absolute;
  display: inline;
  z-index: $top_layer;
  padding: 0.25rem 0.5rem;
  max-width: 35rem;
  width: max-content;
  border: 1px solid $line-component;
  border-radius: 0.25rem;
  background-color: rgba(80, 80, 80, 0.8);
  color: white;
  text-transform: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0, opacity 0;
}

.tooltip_danger {
  border: 1px solid $danger-faint;
  background-color: $danger-background;
  color: $danger-regular;
}

.tooltip_parent {
  position: relative;
}

.tooltip_parent:hover > .tooltip {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s 0.5s, opacity 0.1s 0.5s linear;
}
