@import "styles";

.buttons {
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-left: 1rem;
  }
}

.cancel_button:hover {
  color: $danger-regular;
}
