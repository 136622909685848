@import "styles";

.fields_list {
  max-height: 60vh;
  overflow-y: scroll;
  margin-bottom: 1rem;
  border-top: 1px solid $line-main;
  padding-top: 0.75rem;
  border-bottom: 1px solid $line-main;
}

.item {
  display: flex;
  margin-bottom: 0.75rem;
  & > button {
    margin-right: 1rem;
    color: $info-regular;
    &:hover,
    &:focus {
      color: $icon-hover;
    }
  }
  & > span {
    color: $text-fainter;
    display: inline-block;
    margin-left: 0.75rem;
    font-style: italic;
    font-size: 0.875em;
    line-height: 1.8;
  }
}

.item-ACTIVE {
  composes: item;
  color: $text-selected;
  & > button {
    color: $icon-selected;
  }
}
