@import "styles";
@import "../../../features";

.main {
  composes: feature from "../../../../app/app.module.scss";
  &-HIDDEN {
    composes: feature from "../../../../app/app.module.scss";
    display: none;
  }
}

.contents {
  display: flex;
  padding: 2rem;
  width: 100%;
}

.services_list {
  margin-right: 1.5rem;
  width: 18rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.search_wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}

.search_starter {
  margin-bottom: 1.5rem;
  max-width: 100%;
  width: fit-content;
}
