/*
 * Transitions and animations ease functions.
 */

$ease_slow_start: cubic-bezier(0.22, 0.61, 0.36, 1);
$ease_slow_end: cubic-bezier(0.55, 0.06, 0.68, 0.19);
$ease_slow_start_end: cubic-bezier(0.65, 0.05, 0.36, 1);

$bounce_slow_start: cubic-bezier(0.32, -0.23, 0.68, 0.19);
$bounce_slow_end: cubic-bezier(0.22, 0.61, 0.63, 1.37);
$bounce_slow_start_end: cubic-bezier(0.63, -0.3, 0.36, 1);
