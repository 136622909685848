@import "styles";

.header {
  display: flex;
  margin-bottom: 1rem;
  border-bottom: 1px solid #cfd5de;
  color: #9fa1a3;
  font-size: 0.875rem;
  align-items: center;
  & > h3 {
    margin-bottom: 0;
    padding: 0.25rem;
    color: $text-regular;
    font-size: 1rem;
    flex-grow: 1;
  }
}

.progress_wrapper {
  display: flex;
  align-items: center;
}

.progress_label {
  margin: 0;
}

.spinner {
  margin-left: 1rem;
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
}

// .numbered_icon_wrapper {
//   display: inline-block;
//   margin-right: 0.875rem;
//   font-size: 0.75rem;
//   & > sup {
//     display: inline-block;
//     font-weight: bold;
//   }
// }

// .icon {
//   width: 1rem;
//   &_check {
//     composes: icon;
//     color: $success-regular;
//   }
//   &_danger {
//     composes: icon;
//     margin-right: 0.0625rem;
//     color: $danger-regular;
//   }
//   &_attention {
//     composes: icon;
//     margin-right: 0.25rem;
//     color: $attention-regular;
//   }
// }
