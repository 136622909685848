@import "styles";

.situation_wrapper {
  margin-bottom: 1rem;
}

.label {
  margin-bottom: 0;
  color: $text-strong;
}

.situation_value {
  margin-left: 1.5rem;
}

.expand_details_button {
  &-EXPANDED,
  &:hover,
  &:focus {
    color: $button-default;
  }
}

.indented_details {
  padding-left: 1.375rem;
}

.icon {
  margin-right: 0.5rem;
  width: 1rem;
  &_disabled {
    composes: icon;
    color: $icon-regular;
  }
  &_check {
    composes: icon;
    color: $success-regular;
  }
  &_danger {
    composes: icon;
    color: $danger-regular;
  }
  &_attention {
    composes: icon;
    color: $attention-regular;
  }
}

.debt_value {
  display: block;
  color: $danger-regular;
}