@import "styles";

$border_radius: 0.5rem;

.card_results {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.screenshots {
  width: 8rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.results_summary {
  display: flex;
  flex-grow: 1;
}
