@import "styles";


.label {
  margin-bottom: 0.5rem;
  text-align: right;
  font-size: 0.875rem;
}

.bar {
  height: 0.125rem;
  border-radius: 0.0625rem;
  background: $line-main;
  width: 100%;
  & > div {
    height: 100%;
    border-radius: 0.0625rem;
    background: $info-regular;
  }
}

