@import "styles";

$cell_padding: 0.5rem 1.25rem 0.5rem 0;
$gutter-size: 0.5rem;

.table_wrapper {
  overflow-x: scroll;
  width: 100%;
}

.table {
  margin: 0 $gutter-size 0.5rem;
  min-width: calc(100% - 1rem);
  width: max-content;
  & td {
    line-height: 1;
    /** Stretch last column */
    &:not(:last-child), &:not(:last-child) {
      white-space: nowrap;
    }
    &:last-child {
      width: 100%;
    }
  }
  & > tbody > tr:nth-child(even) {
    background-color: $bg-table-stripe;
    & > td:first-child {
      background-color: $bg-table-stripe;
    }
  }
  & > tbody > tr:nth-child(odd) > .cell_checkbox {
    background-color: white;
  }
}

.fixed_layout {
  table-layout: fixed;
}

.icon_cell_header {
  width: 2rem;
}

.non_editable_cell {
  padding: $cell_padding;
}

.editable_cell {
  padding-right: 0.5rem;
}

.centered {
  text-align: center;
}

.header_row {
  border-bottom: 1px solid $line-component;
  color: $text-regular;
}

.column_header {
  padding: $cell_padding;
  background-color: white;
  vertical-align: bottom;
  text-align: left;
  font-weight: normal;
  &-EDITABLE {
    composes: column_header;
    padding-left: 0.5rem;
  }
}

.cell_spinner {
  text-align: center;
}

.cell_close {
  composes: tooltip_parent from global;
  & > button {
    width: 1.5rem;
    color: $danger-regular;
    font-size: 1.25rem;
    line-height: 1;
    &:hover, &:focus {
      color: $danger-strong;
    }
  }
  & > span {
    bottom: 0;
    left: 0.5rem;
    transform: translateY(100%);
  }
}

.cell_checkbox {
  position: sticky;
  left: 0;
  padding: 0 0.5rem 0 1rem;
}

.add_item_button::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: "+";
}