@import "styles";
@import "../../table";

.filter_text {
  display: flex;
}

.text {
  min-width: 0;
  flex-grow: 1;
}

._casing_button {
  border: none;
  background-color: transparent;
  color: $text-menu-regular;
  cursor: pointer;
  flex-shrink: 0;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.casing_button-INSENSITIVE {
  composes: _casing_button;
  composes: visible_on_hover from "./filter.module.scss";
}

.casing_button-SENSITIVE {
  composes: _casing_button;
  color: $text-selected;
}

.syntax_helper_popup_menu {
  left: 0;
  margin-top: 1.75rem;
}
