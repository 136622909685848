@import "sanitize.css";
body {
  box-sizing: border-box;
  margin: 0;
  background-color: #F7F7F6;
  color: #939599;
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
  /* See https://css-tricks.com/box-sizing/ */
  box-sizing: inherit;
}

input, textarea, button, a {
  padding: 0;
  outline: none;
  box-shadow: none;
  font-style: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.5;
}

a, th:focus, div:focus, input:focus, button:focus, textarea:focus {
  outline: none;
  box-shadow: none;
}

span {
  line-height: 1.5;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

button, label {
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
}

p, h1, h2, h3, h4, h5 {
  margin-top: 0;
  font-weight: normal;
}

h1, h2, h3, h4, h5 {
  letter-spacing: 1px;
  color: #676A6C;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

h4 {
  font-size: 0.875rem;
}

a {
  display: inline-block;
  outline: none;
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  color: #63ade8;
  text-decoration: underline;
}

section {
  margin-bottom: 4rem;
}