@import "styles";

.header {
  display: flex;
  margin-bottom: 0.25rem;
  min-height: 2.25rem;
  align-items: flex-end;
}

.title {
  color: $text-faint;
  margin-bottom: 0;
  flex-grow: 1;
}

.chidren_wrapper {
  flex-grow: 0;
}