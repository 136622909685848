@import "styles";
@import "./side-bar";

$side_bar_hidden_left: $side_bar_hover_width - $side_bar_width;

.hamburger_menu {
  composes: menu_button from global;
  position: fixed;
  top:0;
  left: 0;
  z-index: $side_bar_layer;
  padding: 0.6875rem;
  background: $side_bar_subsection_bg;
}

.side_bar {
  position: fixed;
  top: $side_bar_top;
  bottom: 0;
  left: 0;
  z-index: $side_bar_layer;
  padding-top: 1.25rem;
  background: $side_bar_subsection_bg;
  transition: transform 0.3s $bounce_slow_start_end;
  transform: translateX(-100%);
  &-OPEN {
    composes: side_bar;
    transition: transform 0.3s $ease_slow_start_end;
    transform: translateX(-2rem);
  }
}

.feature_button {
  composes: menu_button from global;
  position: relative;
  display: block;
  padding: 1rem 2rem 1rem 3rem;
  width: 26rem;
  & > h4 {
    display: flex;
    margin:0;
    align-items: center;
  }
  & > p {
    margin-bottom: 0;
    margin-left: 2.5rem;
    color: $text-menu-faint;
    font-size: 0.875rem;
  }
  &-ACTIVE {
    composes: feature_button;
    background-color: $bg-menu_hover;
    color: $icon-selected;
  }
  &:hover,
  &:focus {
    background-color: $bg-menu_hover;
    color: $icon-selected;
    text-decoration: none;
  }
}

.icon {
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
}
