@import "styles";
@import "../side-bar/side-bar";

$footer_icons_gutter: 0.75rem;
$footer_bar_height: 2.875rem;
$left_padding: $side_bar_hover_width + $footer_icons_gutter;

.footer_bar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $popup_menus_layer;
  display: flex;
  padding-left: $left_padding;
  height: $footer_bar_height;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  background-color: $bg-card;
  align-items: center;
  & > * {
    position: relative;
    display: flex;
    margin-right: $footer_icons_gutter;
    align-items: center;
  }
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.75rem;
  height: 0.75rem;
}
