@import "styles";
.currency {
  display: flex;
  margin: 0;
  &::before {
    padding-right: 0.25rem;
    color: $text-fainter;
    content: "R$";
    text-align: left;
    flex-grow: 1;
  }
}
