/*
 * z-index values for all application layers.
 */
$table_headers_layer: 1;
$table_filters_layer: 2;
$feature_header_layer: 3;
$popup_menus_layer: 4;
$footer_bar_layer: 5;
$top_bar_layer: 6;
$side_bar_layer: 7;
$modal_layer: 8;
$top_layer: 9;
