@import "styles";

.carousel_card {
  display: table;
  padding-bottom: 0;
}

.carousel_thumbnail {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  &:last-child {
    margin-right: 0;
  }
}

.thumbnail {
  display: flex;
  width: 7rem;
  height: 5rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  color: $text-fainter;
  padding: 0.25rem;
  &-IMAGE {
    composes: thumbnail;
    align-items: flex-end;
    justify-content: flex-end;
    & > span {
      font-size: 0.75rem;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 0 0.25rem;
      border-radius: 0.125rem;
      color: white;
    }
  }
  &-EMPTY {
    composes: thumbnail;
    align-items: center;
    text-align: center;
  }
}

.enlarged_image_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $top_layer;
  overflow-y: auto;
  background-color: rgba(40, 40, 40, 0.85);
  & > img {
    padding: 2rem;
    width: 100%;
  }
}

.enlarged_image_top_bar {
  position: sticky;
  top: 0;
  display: flex;
  padding: 1rem;
  height: 4.25rem;
  background-color: $bg-topnav;
  color: white;
  align-items: center;
}

.enlarged_image_top_bar_center {
  text-align: center;
  flex-grow: 1;
  justify-content: center;
}

.image_label {
  display: inline-block;
  min-width: 9rem;
}

.close_enlarged {
  color: white;
  &:hover,
  &:focus {
    color: $danger-regular;
  }
}

.navigate_enlarged {
  padding: 0.5rem;
  &:first-child {
    margin-right: 1.5rem;
  }
  &:last-child {
    margin-left: 1.5rem;
  }
}

.no_image_message {
  display: flex;
  width: 90%;
  margin: 4rem auto;
  padding: 4rem;
  background-color: white;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
}
