@import "styles";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal_layer;
  display: flex;
  overflow: scroll;
  background: rgba(255, 255, 255, 0.65);
  opacity: 0;
  animation: reveal 0.3s $ease_slow_end forwards;
  backdrop-filter: blur(0.5rem);
  align-items: center;
  justify-content: center;
  & > * {
    min-width: 25rem;
    max-width: 45rem;
    opacity: 0;
    transform: translateY(100%);
    animation: slide_up 0.2s $ease_slow_start_end 0.2s forwards;
  }
}
@keyframes reveal {
  100% {
    opacity: 1;
  }
}
@keyframes slide_up {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
