@import "styles";

.message_modal {
  width: 35rem;
  & > p {
    color: $text-faint;
    margin-bottom: 2rem;
  }
  & > button {
    margin-left: auto;
    display: block;
  }
}
