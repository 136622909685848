@import "../../table";

.filter_options {
  width: 100%;
  cursor: pointer;
  & > p {
    display: flex;
    margin: 0;
    width: 100%;
    color: $icon-selected;
    text-align: left;
    & > span:first-child {
      overflow: hidden;
      padding-right: 0.25rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
    }
  }
}

.down_icon {
  composes: visible_on_hover from "./filter.module.scss";
}

.filter_options-POPUP_ACTIVE {
  composes: filter_options;
  color: $icon-selected;
}

.popup_menu {
  left: 0;
  margin-top: $table_cell_vertical_padding;
  min-width: 100%;
  border-top-left-radius: 0;
}
