@import "styles";

.button {
  display: inline-block;
  cursor: pointer;
  align-items: center;
  &::before {
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid $icon-regular;
    border-radius: 0.125rem;
    content: "\2800";
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.3;
  }
  &:hover,
  &:focus {
    color: $button-default;
    &::before {
      border-color: $button-default;
    }
  }
  &-CHECKED {
    composes: button;
    color: $button-default;
    &::before {
      border-color: $button-default;
      content: "\2713";
    }
  }
}
