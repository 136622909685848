@import "styles";

.button {
  border: 1px solid;
  border-radius: 0.25rem;
  &:focus {
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.3);
  }
}

.with_tooltip {
  composes: tooltip_parent from global;
  position: relative;
}

.tooltip {
  composes: tooltip from global;
  bottom: 0.5rem;
  transform: translateY(100%);
  &-LEFT {
    composes: tooltip;
    left: 0.5rem;
  }
  &-RIGHT {
    composes: tooltip;
    right: 0.5rem;
  }
}

.layout-REGULAR {
  padding: 0.5rem 1rem;
}

.layout-SLIM {
  padding: 0.25rem 1rem;
}

.color-DISABLED {
  composes: button;
  border-color: $icon-faint;
  background-color: $bg-disabled;
  color: $text-faintest;
}

.color-MAIN {
  composes: button;
  border-color: $button-default;
  background-color: $button-default;
  color: white;
  &:hover,
  &:focus {
    background-color: $button-hover;
  }
}

.color-MAIN_SECONDARY {
  composes: button;
  border-color: $button-default;
  background-color: transparent;
  color: $button-default;
  &:hover,
  &:focus {
    background-color: $button-default;
    color: white;
  }
}

.color-DANGER {
  composes: button;
  border-color: $danger-regular;
  background-color: $danger-regular;
  color: white;
  &:hover,
  &:focus {
    background-color: $danger-faint;
  }
}

.color-DANGER_SECONDARY {
  composes: button;
  border-color: $danger-regular;
  background-color: transparent;
  color: $danger-regular;
  &:hover,
  &:focus {
    background-color: $danger-regular;
    color: white;
  }
}

.color-TRANSPARENT {
  &:hover,
  &:focus {
    color: $button-hover;
  }
}
