@import "styles";
@import "../table";
@import "../filters/filters.module.scss";

$header_sticky_top: $table_sticky_top + $filters_height;

.header_cell {
  position: sticky;
  top: $header_sticky_top;
  z-index: $table_headers_layer;
  padding: $header_cell_padding;
  background-color: $table_header_color;
  text-align: center;
  font-weight: 400;
  font-size: $table_font_size;
  &-left {
    composes: header_cell;
    text-align: left;
    & > .header_toolbar {
      left: 0.5rem;
      transform: translateX(0);
    }
  }
  &:hover .tooltip {
    left: $table_cell_horizontal_padding;
    margin-top: 0.5rem;
    transform: translateY(100%);
    visibility: visible;
    opacity: 1;
    transition: visibility 0.1s 0.5s, opacity 0.1s 0.5s linear;
  }
  &:focus,
  &:focus-within {
    z-index: $table_filters_layer;
    & .tooltip {
      display: none;
    }
    & > .header_toolbar {
      display: block;
    }
  }
}

.action {
  composes: soft_action from global;
}

.tooltip {
  composes: tooltip from global;
}

.header_cell_ordered {
  z-index: $table_filters_layer;
  & .tooltip {
    display: none !important;
  }
}

.remove_order_icon {
  position: absolute;
  right: -0.625rem;
  width: 0.75rem;
  height: 0.75rem;
  background: $bg-card;
  color: $danger-regular;
  opacity: 0;
}

.header_toolbar {
  position: absolute;
  top: 2.125rem;
  left: 50%;
  display: none;
  padding: 0.5rem 0.75rem;
  width: max-content;
  border-radius: 0.25rem;
  background: $bg-menu;
  color: $text-menu-faint;
  transform: translateX(-50%);
  &:hover {
    display: block;
    &:hover ~ .tooltip {
      display: none;
    }
  }
  & > button {
    margin-right: 0.75rem;
    &:last-child {
      margin: 0;
    }
  }
}

.remove_order_button {
  composes: toggle_on from global;
  position: relative;
  height: 1.5rem;
  & > span {
    position: absolute;
    font-size: 0.625rem;
  }
  &:hover > .remove_order_icon,
  &:focus > .remove_order_icon {
    opacity: 1;
  }
  &:hover ~ .header_toolbar,
  &:focus ~ .header_toolbar {
    display: block;
  }
  &:hover .tooltip {
    display: none;
  }
}

.table_actions {
  composes: header_cell;
  position: sticky;
  left: $table_sticky_left;
  z-index: $popup_menus_layer;
  color: $icon-faint;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $icon-selected;
  }
}

.table_actions-POPUP_ACTIVE {
  composes: table_actions;
  color: $icon-selected;
}

.action_icon {
  margin-right: 0.375rem;
  width: 1rem;
  height: 1rem;
  color: $icon-faint;
}
