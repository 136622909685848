@import "styles";

.services_list {
  padding: 0 1rem;
}

.loading_mesage {
  padding: 0.75rem;
  color: $text-fainter;
  font-style: italic;
  font-size: 0.875rem;
}

.list {
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.item {
  display: flex;
  cursor: pointer;
  &::before {
    display: inline-block;
    margin-top: 0.1875rem;
    margin-right: 0.5rem;
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid $icon-regular;
    border-radius: 0.125rem;
    content: " ";
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.3;
    flex-shrink: 0;
  }
  &:hover,
  &:focus {
    color: $button-default;
    &::before {
      border-color: $button-default;
    }
  }
}

.item_text_version {
  display: inline-block;
  margin-left: 0.5rem;
  font-style: italic;
  font-size: 0.875em;
}

.item-CHECKED {
  composes: item;
  color: $button-default;
  &::before {
    border-color: $button-default;
    content: "\2713";
  }
}

.item-PARTIAL {
  composes: item;
  color: $button-default;
  &::before {
    border-color: $button-default;
    content: "\2013";
  }
}

.sublist {
  margin-bottom: 1rem;
  margin-left: 2.75rem;
}

.sublist_header {
  display: flex;
  margin-bottom: 0.25rem;
}

.chevron {
  flex-grow: 0;
}
