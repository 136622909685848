@import "styles";

.flex_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message_box {
  margin-bottom: 2rem;
  padding: 1rem 1.5rem;
  border: 1px solid $attention-strong;
  border-radius: 0.25rem;
  background-color: $attention-background;
  color: $attention-strong;
}

.flex_edges {
  position: relative;
  flex-grow: 1;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
}

.login_card_wrapper {
  width: 30rem;
  flex-grow: 0;
}

.textbox {
  margin-bottom: 1.5rem;
}

.button {
  display: block;
  margin-top: 3rem;
  margin-left: auto;
}

.error_box {
  padding: 1rem 2rem;
  border: 1px solid $danger-faint;
  border-radius: 0.25rem;
  background-color: $danger-background;
  color: $danger-regular;
  text-align: center;
}
