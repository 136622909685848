.labeled_total {
  composes: labeled_total from '../list-multas/list-multas.module.scss'
}

.table_wrapper {
  margin-bottom: 1rem;
}

.empty_table_messagebox {
  composes: empty_table_messagebox from '../list-multas/list-multas.module.scss'
}