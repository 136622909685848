@import "styles";

.icon {
  width: 1rem;
  height: 1rem;
}

.close {
  composes: icon;
  color: $danger-regular;
}

.check {
  composes: icon;
  color: $success-regular;
}
