@import "styles";

.menu {
  position: absolute;
  z-index: $top_layer;
  overflow-y: auto;
  padding: 0.25rem 0;
  max-height: calc(max(12rem, 40vh));
  width: max-content;
  border-radius: 0.25rem;
  background-color: $bg-menu;
  color: $text-menu-regular;
}

.list_item {
  font-size: inherit;
  &:hover,
  &:focus-within {
    background-color: $bg-menu_hover;
    color: $text-menu-hover;
    & > button {
      color: inherit;
    }
  }
  & > * {
    padding: 0.375rem 1rem;
    width: 100%;
  }
  & > p {
    margin: 0;
    cursor: default;
    &:hover,
    &:focus-within {
      background-color: $bg-menu;
      color: $text-menu-regular;
    }
  }
}

.item_main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > svg {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }
}

.list_item-HIGHLIGHT {
  composes: list_item;
  background-color:$bg-menu_hover;
  color: $text-menu-hover;
}

.align-RIGHT {
  text-align: right;
  & > * {
    text-align: right;
    & > .item_main {
      justify-content: flex-end;
      & > svg {
        margin-left: 0.875rem;
        order: 2;
      }
    }
  }
}

.align-LEFT {
  text-align: left;
  & > * {
    text-align: left;
    & > .item_main > svg {
      margin-right: 0.875rem;
      margin-left: 0;
    }
  }
}

.icon_color-DANGER > svg {
  color: $danger-regular;
}

.icon_color-SUCCESS > svg {
  color: $success-regular;
}

.icon_color-ATTENTION > svg {
  color: $attention-regular;
}

.new_item {
  composes: list_item;
}

.details {
  display: block;
  color: $text-menu-faint;
  font-style: italic;
  &-HAS_ICONS-LEFT {
    composes: details;
    margin-left: 1.875rem;
  }
  &-HAS_ICONS-RIGHT {
    composes: details;
    margin-right: 1.875rem;
  }
}
