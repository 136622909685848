@import "styles";
@import "../../table";

$filter_horizontal_padding: 0.625rem;

.filter {
  position: absolute;
  top: 0;
  left: 0;
  // display: flex;
  padding: $table_cell_vertical_padding $filter_horizontal_padding;
  width: calc(100% - 0.125rem);
  border-radius: 0.25rem 0.25rem 0 0;
  &:hover,
  &:focus-within {
    background-color: $bg-menu;
    color: $text-selected;
    & .visible_on_hover {
      visibility: visible;
    }
  }
}

.filter-ACTIVE {
  composes: filter;
  background-color: $table_header_color;
  color: $text-selected;
}

.visible_on_hover {
  visibility: hidden;
}
