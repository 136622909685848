@import "styles";

.tabs_headers_wrapper {
  padding: 0 1rem;
  border-top: 1px solid $line-main;
  border-bottom: 1px solid $line-main;
  background-color: $bg-card_footer;
  font-size: 0.875rem;
}

.navigation {
  display: inline-block;
  margin-right: 1rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $text-selected;
    text-decoration: none;
  }
  &-ACTIVE {
    composes: navigation;
    border-bottom: 2px solid $text-selected;
    color: $text-selected;
  }
}