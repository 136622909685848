@import "styles";

.CHECKED {
  width: 1rem;
  height: 1rem;
  color: $success-regular;
}

.UNCHECKED {
  composes: action from global;
  width: 1rem;
  height: 1rem;
  color: $icon-faint;
}
