@import "./card.module.scss";

.card_footer {
  display: flex;
  padding: $card-large-padding;
  border-top: solid 1px $line-main;
  border-radius: 0 0 $card-border-radius $card-border-radius;
  background-color: $bg-card_footer;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 1.5rem;
    &:first-child {
      margin-left: 0;
    }
  }
}