@import "styles";

.main {
  composes: feature from "../../../../app/app.module.scss";
}

.page_wrapper {
  margin: 0 auto;
  padding: 2rem;
  max-width: 60rem;
}

.verificacao_card_wrapper {
  margin-bottom: 0.25rem;
  transition: transform 0.1s $ease_slow_end;
  transform: scale(1);
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.block_title {
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid $line-main;
  justify-content: center;
  & > span {
    display: table;
    padding: 0 1rem;
    background-color: $bg-main;
    line-height: 1;
    transform: translateY(50%);
  }
}

.verificacao_card {
  display: flex;
  & > div:first-child {
    flex-grow: 1;
  }
}

.info_wrapper > div {
  display: inline-block;
  margin-right: 2rem;
}

.info_title {
  margin-bottom: 0;
}

.status_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bullet_wrapper {
  display: inline-block;
  margin: 0 0.5rem 0 1rem;
  font-size: 2.5rem;
  line-height: 1;
  &_in_progress {
    composes: bullet_wrapper;
    color: #ccc;
  }
  &_success {
    composes: bullet_wrapper;
    color: $success-regular;
  }
  &_danger {
    composes: bullet_wrapper;
    color: $danger-regular;
  }
  &_attention {
    composes: bullet_wrapper;
    color: $attention-regular;
  }
}