@import "styles";

.chevron {
  margin-right: 0.75rem;
  width: 0.375rem;
  cursor: pointer;
  &:hover {
    color: $button-default;
  }
  &-DOWN {
    composes: chevron;
    transform: rotate(90deg);
  }
}