@import "styles";

.archive_search {
  margin-bottom: 2rem;
}

.search_row {
  display: flex;
  margin-bottom: 1rem;
}

.search_text {
  flex-grow: 1;
}

.search_button_wrapper {
  display: flex;
  margin-left: 1rem;
  align-items: flex-end;
}

.search_button {
  display: inline-flex;
  align-items: center;
  & > svg{
    margin-right: 0.5rem;
  }
}

.filters_row {
  display: flex;
}

.date_filters {
  margin-left: 2rem;
  flex-grow: 0;
}

.organization_filters {
  flex-grow: 0;
}