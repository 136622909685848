@import "styles";

.error_message {
  margin-right: 1.5rem;
  color: $danger-regular;
  flex-grow: 1;
}

.error_info_button{
  margin-bottom: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
}

.retry_button {
  composes: button_base from global;
  display: block;
  border-color: $danger-regular;
  & > svg {
    margin-right: 0.5rem;
  }
}

.no_retry_button_like {
  composes: button_base from global;
  display: table;
  border-color: $line-component;
  color: $text-faint;
  cursor: not-allowed;
}