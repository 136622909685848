.new_entry_button::before {
  content: "+";
  display: inline-block;
  margin-right: 0.5rem;
}

.text_input {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 1rem;
}