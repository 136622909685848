.user_button {
  composes: menu_button from global;
  margin-right: 1rem;
}

.action_button {
  display: inline-flex;
  margin-right: 1.25rem;
  color: white;
  align-items: center;
  & > span {
    display: inline-block;
    margin-left: 0.25rem;
  }
}